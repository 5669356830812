import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as style from './card.module.scss';
import gsap from "gsap"

import { Aesop, BotsAtWork, BasicsOf, Workaholic, PostureAnalysis, SignLanguageTranslation, Mastercard, FrogEPFL, PoeticAI, HighMuseum } from '../thumbnail';

const ProjectThumbnail = ({imageSelector}) => {
    switch(imageSelector){
        case 'Aesop':
            return <Aesop />
        case 'BotsAtWork':
            return <BotsAtWork />
        case 'BasicsOf':
            return <BasicsOf />
        case 'Workaholic':
            return <Workaholic />
        case 'PostureAnalysis':
            return <PostureAnalysis />
        case 'SignLanguageTranslation':
            return <SignLanguageTranslation />
        case 'Mastercard':
            return <Mastercard />
        case 'FrogEPFL':
            return <FrogEPFL />
        case 'PoeticAI':
            return <PoeticAI />
        case 'HighMuseum':
            return <HighMuseum />
        default:
            return null;
    }
}

const expandThisCard = (id) => {
    const elem = document.getElementById(id);
    gsap.to(elem, {
        width: '100vw',
        paddingBottom: '35%',
        zIndex: 1000,
        duration: 1,
        ease: "Power3.easeOut",
    });
}

const Card = ({ imageSelector, title, description, categories, link, date, themeColor }) => (
    <div className={style.card}>
        <Link to={link} className={`data-hover`}>
            <div className={style.thumbnail} style={{ backgroundColor: themeColor }} id={`card${imageSelector}`}>                
                <div className={style.image}>
                    <ProjectThumbnail imageSelector={imageSelector} />
                </div>
            </div>
            <div className={style.information}>
                <h3>{title}</h3>
                {/* <p className={`caption`}>{categories}</p> */}
                <p>{description}</p>
            </div>
        </Link>
    </div>
)

Card.propTypes = {
    imageSelector: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    categories: PropTypes.string,
    link: PropTypes.string,
    date: PropTypes.string,
    themeColor: PropTypes.string,
}

Card.defaultProps = {
    imageSelector: ``,
    title: `Project Title`,
    description: ``,
    categories: ``,
    link: `/404`,
    date: `Not specified`,
    themeColor: ``,
}

export default Card
