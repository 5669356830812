import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Aesop = () => (
    <StaticImage
        src={'../images/Aesop.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const BotsAtWork = () => (
    <StaticImage
        src={'../images/Bots At Work.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const BasicsOf = () => (
    <StaticImage
        src={'../images/Basics Of.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const Workaholic = () => (
    <StaticImage
        src={'../images/Workaholic.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const PostureAnalysis = () => (
    <StaticImage
        src={'../images/Posture Analysis.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const SignLanguageTranslation = () => (
    <StaticImage
        src={'../images/Sign Language.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const Mastercard = () => (
    <StaticImage
        src={'../images/Mastercard.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const FrogEPFL = () => (
    <StaticImage
        src={'../images/Frog EPFL.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const PoeticAI = () => (
    <StaticImage
        src={'../images/PoeticAI.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

const HighMuseum = () => (
    <StaticImage
        src={'../images/High Museum.png'}
        width={800}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project Thumbnail"
        loading={`eager`}
    />
)

export { Aesop, BotsAtWork, BasicsOf, Workaholic, PostureAnalysis, SignLanguageTranslation, Mastercard, FrogEPFL, PoeticAI, HighMuseum };